// Add your JS customizations here

jQuery(function () {
    'use strict';
    
    // header dropdown
    jQuery(document).on('mouseover', 'li[dropdown-target]', function() {
        const toggle_id = jQuery(this).attr('dropdown-target');


        jQuery('.menu-dropdown').attr('hidden', ''); // hide all
        const dd = jQuery('.menu-dropdown[toggle-id=' + toggle_id + ']');

        dd.toggleClass('uk-active');

        if (dd.hasClass('uk-active')) {
            dd.removeAttr('hidden');
        } else {
            dd.attr('hidden', '');
        }
    });

    var hero_slider = UIkit.slideshow('.hero-banner-slider .uk-slideshow');
    jQuery('.hero-banner-slider .slider-prev').click(function(e) {
        e.preventDefault();
        hero_slider.show(hero_slider.index - 1);
    });
    jQuery('.hero-banner-slider .slider-next').click(function(e) {
        e.preventDefault();
        hero_slider.show(hero_slider.index + 1);
    });
    
    var slider_desktop = UIkit.slider('.projects-slider .slider-desktop');
    jQuery('.projects-slider .slider-prev-desktop').click(function(e) {
        e.preventDefault();
        slider_desktop.show(slider_desktop.index - 1);
    });
    jQuery('.projects-slider .slider-next-desktop').click(function(e) {
        e.preventDefault();
        slider_desktop.show(slider_desktop.index + 1);
    });

    var slider_mobile = UIkit.slider('.projects-slider .slider-mobile');
    jQuery('.projects-slider .slider-prev-mobile').click(function(e) {
        e.preventDefault();
        slider_mobile.show(slider_mobile.index - 1);
    });
    jQuery('.projects-slider .slider-next-mobile').click(function(e) {
        e.preventDefault();
        slider_mobile.show(slider_mobile.index + 1);
    });

    // get in touch modal toggle
    jQuery(".button").each(function() {
        if (jQuery(this).attr('href') == '#get-in-touch') {
            jQuery(this).attr('uk-toggle', '');
        }
    });

    function projectsAjaxFilter(page, category) {
        var data = { action: 'get_projects', page: page, service: category };
        var projects_list_wrap = jQuery('.projects-block .projects-list-wrap');
        var pagination = jQuery('.projects-block .projects-list .pagination');
        var current_page = jQuery('input[name=current_page]').val();

        projects_list_wrap.empty().hide();
        pagination.hide();
        jQuery('.projects-block .fetching-projects').fadeIn();

        jQuery.ajax({
            type : "POST",
            url : ajaxurl,
            data : data,
            success: function(response) {
                jQuery('.projects-block .fetching-projects').delay(1000).fadeOut();

                if (jQuery.isEmptyObject(response.posts) == true) {
                    projects_list_wrap.empty().fadeOut();
                    pagination.fadeOut();
                    jQuery('.projects-block .no-posts-found').fadeIn();
                } else {
                    function appendFilteredPosts() {
                        jQuery.each(response.posts, function( index, value ) {
                            const post_id = value.post_id;

                            jQuery(projects_list_wrap).append(
                                '<div class="project-item uk-padding-large uk-padding-remove-horizontal">'+
                                    '<div class="uk-container">'+
                                        '<div class="uk-grid uk-child-width-1-1 uk-child-width-1-2@m" uk-grid>'+
                                            '<div class="project-content">'+
                                                '<ul class="project-services uk-list" id="post-'+post_id+'"></ul>'+
                                                '<h2 class="h2">'+value.post_title+'</h2>'+
                                                '<p>'+value.intro_text+'</p>'+
                                                '<a href="'+value.permalink+'" class="button button-default">Fint out more</a>'+
                                            '</div>'+
                                            '<div class="project-image-wrap">'+
                                                '<div class="project-image" style="background-image: url('+value.image+');"></div>'+
                                            '</div>'+
                                        '</div>'+
                                    '</div>'+
                                '</div>'
                            );

                            jQuery.each(value.services, function( index, value ) {
                                jQuery('.projects-block .project-services#post-'+post_id).append(
                                    '<li class="text-gold uk-inline-block uk-text-uppercase uk-text-bold uk-margin-remove-top">'+value+'</li>'
                                );
                            });
                        });
                        projects_list_wrap.delay(1500).fadeIn();
                    }

                    function appendPages() {
                        if (response.max_num_pages > 1) {
                            pagination.delay(1500).fadeIn();
                        } else {
                            pagination.fadeOut();
                        }
                        pagination.find('.pages').empty();

                        for (var i = 0; i < response.max_num_pages; i++) {
                            if ((i+1) == current_page) {
                                var $active_class = 'active';
                            } else {
                                var $active_class = '';
                            }
                            pagination.find('.pages').append('<span class="page page-number '+ $active_class +'" data-val="' + (i+1) + '">' + (i+1) + '</span>');
                        }
                    }

                    appendFilteredPosts();
                    appendPages();

                    jQuery('.projects-block .no-posts-found').fadeOut();
                }
            }
        });
    }

    var page_num = jQuery('input[name=current_page]').val();

    jQuery(document).on('click', '.projects-block .pagination .page-number', function(e) {
        e.preventDefault();
        document.getElementById("projects-block").scrollIntoView();
        page_num = jQuery(this).attr('data-val');
        console.log(page_num);
        jQuery('input[name="current_page"]').val(page_num).trigger('input');
        projectsAjaxFilter(page_num);
    });

    // Click event for "Next" button
    jQuery('.projects-block .pagination .page.page-next').on('click', function() {
        var total_pages = jQuery('.projects-block .pagination .pages .page').length;
        if (page_num != total_pages) {
            page_num++; // Increment page number
        }
        jQuery('input[name="current_page"]').val(page_num).trigger('input');
        projectsAjaxFilter(page_num);
    });

    // Click event for "Previous" button
    jQuery('.projects-block .pagination .page.page-prev').on('click', function() {
        if (page_num > 1) {
            page_num--; // Decrement page number if not on the first page
        }
        jQuery('input[name="current_page"]').val(page_num).trigger('input');
        projectsAjaxFilter(page_num);
    });

    projectsAjaxFilter(page_num);

    jQuery('.projects-block select[name=project_services]').on('input', function(e) {
        e.preventDefault();
        var category = jQuery(this).val();
        jQuery('input[name="current_page"]').val(1).trigger('input');
        projectsAjaxFilter(1, category)
    });

    jQuery(window).scroll(function() {
        var header = jQuery('header#header');
        var scrollPosition = jQuery(window).scrollTop();
    
        if (scrollPosition > 50) { // Adjust the scroll position threshold as needed
          header.addClass('active');
        } else {
          header.removeClass('active');
        }
    });


    jQuery('section.text-block:odd').addClass('odd');
    jQuery('section.text-block:even').addClass('even');
    jQuery('section.text-block').last().addClass('last');

    // jQuery('.header-wrap .main-menu ul li.menu-item a[data-toggle=dropdown]').on('mouseenter', function() {
    //     jQuery('.site-main').addClass('dd-open');
    // }).on('mouseleave', function() {
    //     jQuery('.site-main').removeClass('dd-open');
    // });

    jQuery(document).ready(function($) {
        jQuery('input[type="checkbox"]').change(function() {
            var label = jQuery(this).parent('label');
            if (jQuery(this).is(':checked')) {
                label.addClass('checked-label');
            } else {
                label.removeClass('checked-label');
            }
        });
    });

    jQuery(".main-menu ul li.menu-item-65 > a").click(function() {
        var url = window.location.protocol+'//'+window.location.hostname+'/our-services/';
        window.location.href = url;
    });
});